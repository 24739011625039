body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --color-primary: #cfcb8e;
  --color-primary-dark: #a9b072;
  --color-primary-light: #e3dbb4;
  --color-secondary: #fcb95f;
  --color-secondary-dark: #fc9d55;
  --color-secondary-light: #ffe7c3;
  --color-tertiary: #f8baa3;
  --color-tertiary-dark: #f2afa0;
  --color-tertiary-light: #eee0d2;
  --color-dark: #84542e;
  --color-light: #eee0d2;
  --color-text-primary: black;
  --color-text-secondary: black;
}
