.window__container {
  position: absolute;
  background: white;
  border: 2px solid #000;
  border-radius: 10px;
  transition: border-radius 1s ease 0s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.window__container--fullscreen {
  border-radius: 0px !important;
}

.window__container--not-dragging {
  transition: transform 0.5s ease 0s;
}

.window__handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=);
  background-position: bottom right;
  padding: 0 5px 5px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.window__header {
  display: flex;
  cursor: pointer;
  height: 30px;
  background: var(--color-primary);
  color: var(--color-text-secondary);
  border-radius: 8px 8px 0px 0px;
}

.window__body {
  display: flex;
  flex-direction: column;
  border-top: 2px solid black;
  padding: 10px;
  overflow: auto;
  height: calc(100% - 22px);
}

.window__resizable--auto {
  transition: width 0.5s ease 0s, height 0.5s ease 0s;
}

.window__header-body {
  flex: auto;
  z-index: 1;
}

.header__icon {
  border: 2px solid #000;
  width: 14px;
  height: 14px;
  margin: 8px 0px 7px 6px;
  padding: 0px;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
}

.header__icon--red:hover {
  background: crimson;
}

.header__icon--red {
  background: salmon;
}

.header__icon--amber:hover {
  background: goldenrod;
}

.header__icon--amber {
  background: gold;
}

.header__icon--green:hover {
  background: limegreen;
}

.header__icon--green {
  background: lightgreen;
}

.header__heading {
  position: absolute;
  margin: 0px;
  height: 30px;
  top: 5px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 16px;
}

.back__icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 20px;
}

.back__icon-svg {
  margin-right: 10px;
}
