Body {
  overflow: hidden;
}

.App {
  position: fixed;
  width: 100vw;
  height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
}
