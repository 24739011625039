.me__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.me__cardstack {
  margin: 20px 0px 50px 0px;
}

.me__card {
  display: flex;
  justify-content: center;
  width: 220px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: black;
  text-decoration: none;
}

.me__card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background: var(--color-secondary-light);
}
