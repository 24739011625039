.projects__body {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-auto-rows: 400px;
  grid-gap: 20px;
  justify-content: center;
  margin: 50px 0px;
}

.projects__card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: 0.3s;
}

.projects__card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background: var(--color-secondary-light);
}

.projects__card-image {
  width: 300px;
  height: 300px;
  border-radius: 5px 5px 0px 0px;
  background: #f4f7fa;
}

.projects__card-details {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.projects__card-name {
  margin-bottom: 10px;
}

.projects__card-description {
  font-size: small;
}

.projects__page-body {
  padding: 50px;
}

.vowels__body {
  padding: 10px;
}

.vowels__alphabet {
  display: grid;
  grid-template-columns: repeat(auto-fill, 30px);
  grid-auto-rows: 30px;
  grid-gap: 0px;
  justify-content: center;
  margin: 20px 0px;
}

.vowels__letter {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-secondary-light);
}

.vowels__letter--vowel {
  background: var(--color-secondary-dark);
  color: white;
}

.vowels__letter--y {
  background: var(--color-secondary);
  color: white;
}
