.tldr__wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tldr {
  display: flex;
  justify-content: center;
}

.tldr__link {
  margin: 0px 0px 20px 10px;
}

.tldr__image {
  height: 300px;
  min-height: 300px;
  align-self: center;
}
